<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-btn
      color="primary"
      dark
      style="text-transform: none"
      @click="sendReport"
    >
      レポート送信
      <v-icon
        dark
        right
      >
        {{ icons.mdiSendOutline }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiSendOutline } from '@mdi/js'
import Loading from '@/views/components/load/Loading.vue'

const axiosBase = require('axios')

export default {
  components: {
    Loading,
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  methods: {
    sendReport() {
      const axios = axiosBase.create({
        baseURL: 'http://20.222.77.210/fss', // バックエンドB のURL:port を指定する
        headers: {
          'Access-Control-Allow-Methods': 'POST',
          'content-type': 'application/json',
        },
      })

      this.loading = true
      axios
        .post('ws/send-report!execute.sv', null, null)
        .then(response => {
          console.log(response)

          this.loading = false
          this.$toasted.success('レポート送信コマンドが実行されました。')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  setup() {
    return {
      loading: false,
      icons: {
        mdiSendOutline,
      },
    }
  },
}
</script>
